import React, { FC, useEffect, useRef, MutableRefObject } from 'react';
import { Typography, Theme, useTheme } from '@mui/material';
import Slide from './Slide';
import Caption from './Caption';
import RichText from '../../rich-text';
import NavBtn from './NavBtn';
import { useSliderContext } from './GallerySlider';
import * as Move from './lib/move';
import { GalleryProps as Props, Omits } from './types.d';
import useAttrs from '../../../hooks/useAttrs';
import { Animate, Size, Shadow } from '../../../constants';
import { grid, cell } from './lib/grid';

/**
 * Helper Funcs
 */

const createHelpers = (auto: MutableRefObject<boolean>) => ({
  cancelAuto() {
    auto.current = false;

    return auto;
  },
});

const Container: FC<Props> = ({
  children,
  galleryHeader,
  galleryIntroCopy,
  autoPlay,
  slideTiming,
  colorBackground = 'None',
  ...props
}) => {
  if (!children) {
    return null;
  }

  const theme = useTheme();
  const { palette, spacing, breakpoints } = theme;

  // Vars
  const { dispatch, context } = useSliderContext();
  const $track = useRef<HTMLDivElement>(null);
  const $slides = useRef<Array<HTMLDivElement>>([]);
  const $auto = useRef(!!autoPlay);
  const $time = useRef((slideTiming || 6) * 1000);
  const { forward } = context;
  const { className = '', ...attrs } = useAttrs<Props>(props, Omits);
  const { cancelAuto } = createHelpers($auto);

  /**
   * onMount
   */

  useEffect(() => {
    const track = $track.current;

    if (track) {
      const list = track.querySelectorAll<HTMLDivElement>('.GallerySlide');

      $slides.current = [...list];
    }
  }, []);

  /**
   * onStateChanges
   */

  useEffect(() => {
    const list = $slides.current;
    const currentSlide = list[context.position];
    const prevSlide = list[context.prev];
    const nextSlide = list[context.next];
    let startTime = 0;

    // Animate frames
    const slideTransitions = (timeStamp: DOMHighResTimeStamp): void => {
      if (!startTime) {
        startTime = timeStamp;
      }

      const totalTime = timeStamp - startTime;

      Move.clear(currentSlide);

      if (forward) {
        Move.setRight(currentSlide);
      }

      if (totalTime >= 16) {
        startTime = 0;

        Move.center(currentSlide);

        if (forward) {
          Move.left(prevSlide);
        } else {
          Move.right(nextSlide);
        }
      } else {
        window.requestAnimationFrame(slideTransitions);
      }
    };

    if ($auto.current) {
      const playNext = window.setTimeout(() => {
        if ($auto.current) {
          dispatch({ type: Animate.NEXT });
        }
        window.clearTimeout(playNext);
      }, $time.current);
    }

    window.requestAnimationFrame(slideTransitions);
  }, [context]);

  return (
    <section
      css={{
        contain: 'content',
        paddingBottom: spacing(2),
        margin: spacing(6, 0),
        overflow: 'hidden',
        boxShadow: Shadow.ONE,

        [breakpoints.up('sm')]: {
          padding: spacing(0, 2.5, 4.25),
        },

        background: colorBackground === 'None' ? palette.common.white
          : colorBackground === 'Blue' ? palette.secondary.main
          : colorBackground === 'Grey' ? palette.grey[100]
          : palette.common.white,

        '& h1, & > p': {
          boxSizing: 'content-box',
          maxWidth: 800,
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: spacing(0, 2),

          [breakpoints.up('sm')]: {
            padding: spacing(0, 1.25),
          },
        },

        '& h1': {
          marginTop: spacing(2.5),

          [breakpoints.up('sm')]: {
            marginTop: spacing(4),
          },
        },

        '& > p': {
          marginTop: spacing(3),

          [breakpoints.up('sm')]: {
            marginTop: spacing(4.5),
          },
        },

        '& h1 + p': {
          marginTop: spacing(2),
        },

        '& .graphical span': {
          width: 1,
          height: 1,
          position: 'absolute',
          overflow: 'hidden',
        },
      }}
      aria-label={galleryHeader || undefined}
      {...attrs}
    >
      {galleryHeader && <Typography variant="h1">{galleryHeader}</Typography>}
      <RichText>{galleryIntroCopy}</RichText>
      <div
        css={{
          contain: 'layout',
          maxWidth: 820,
          margin: spacing(2, 'auto', 0),

          [breakpoints.up('sm')]: {
            padding: 10,
            marginTop: spacing(2.5),
          },
        }}
        onMouseDown={cancelAuto}
        onTouchStart={cancelAuto}
      >
        <div css={{
          contain: 'size',
          padding: '75% 0 0',
          position: 'relative',
          transformStyle: 'preserve-3d',
        }} ref={$track}>
          {children.map((item, index) => (
            <Slide
              num={index}
              key={`slide-${item.contentful_id}${index}`}
              {...item}
            />
          ))}
          <NavBtn use="Prev">Previous</NavBtn>
          <NavBtn use="Next">Next</NavBtn>
        </div>
      </div>
      <div
        css={{
          ...grid('auto auto', '1fr auto'),
          boxSizing: 'content-box',
          contain: 'content',
          maxWidth: 800,
          padding: spacing(0, 2),
          position: 'relative',
          margin: spacing(2, 'auto', 0),
          rowGap: spacing(2),

          [breakpoints.up('sm')]: {
            padding: spacing(0, 1.25),
            marginTop: spacing(2.5),
            rowGap: 0,
          },
        }}
        onMouseDown={cancelAuto}
        onTouchStart={cancelAuto}
      >
        <p css={{
          ...cell('Row', 1, 2),
          ...cell('Column', 1, 3),
          margin: 0,
          fontSize: '1.4rem',
          fontWeight: 700,
          wordSpacing: '.4ch',
          letterSpacing: '.06ch',

          [breakpoints.up('sm')]: {
            ...cell('Column', 2, 3),
          },
        }}>
          {context.position + 1} / {context.total}
        </p>
        {children.map((item, index) => (
          <Caption
            num={index}
            key={`caption-${item.contentful_id}${index}`}
            {...item}
          />
        ))}
      </div>
    </section>
  );
};

export default Container;
